<template>
  <CModal id="PrintLabel" class="ActionModel" :show.sync="Toggle" :centered="true" size="lg" :closeOnBackdrop="!Submit"
          @update:show="HandleHideModal">
    <template>
      <CAlert color="info">
        <CIcon name="cil-bell" /> 僅有<b>{{ $t('Logistics.LogisticsSystem.SF-LOGISTICS') }}</b>配送系統、<b>準備中</b>、<b>已付款</b>及<b>代收款</b>的訂單才能列印托運單，若訂單僅改變配送狀態未曾建立物流單將無法正常列印托運單。
      </CAlert>
      <CRow form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.LogisticsSystem') }}
        </CCol>
        <CCol sm="9" class="d-flex align-items-center">
          {{ $t('Logistics.LogisticsSystem.SF-LOGISTICS') }}
        </CCol>
      </CRow>
      <CSelect horizontal :label="$t('Logistics.Name')" :options="LogisticsOption" v-model="LogisticsData.ID" :value.sync="LogisticsData.ID" :placeholder="$t('Global.PleaseSelect')" @change="GerOrderList()" />
      <CInput :label="$t('Order.LogisticsOrderOption.OrderCount')" :value="OrderList.length" horizontal disabled />
      <CSelect horizontal :label="$t('Logistics.PrintFormat')" :options="PrintFormatOptions" v-model="LogisticsData.PrintFormat" :value.sync="LogisticsData.PrintFormat" :placeholder="$t('Global.PleaseSelect')" />
    </template>
    <CElementCover v-if="(Loading === true || Submit === true)" :opacity="0.75" class="py-5">
      <CSpinner color="info"/>
    </CElementCover>
    <template #header-wrapper>
      <header class="modal-header border-bottom-0">
        <h5 class="modal-title">{{ $t('Order.BulkPrintSingleLabel') }}</h5>
        <CButtonClose @click="HandleHideModal()"/>
      </header>
    </template>
    <template #footer-wrapper>
      <footer class="modal-footer border-top-0">
        <CButton @click="HandleHideModal()" color="light">{{ $t('Global.Cancel') }}</CButton>
        <CButton id="CreateLogisticsOrderSubmit" @click="RedirectPrintShipmentLabel()" color="success" :disabled="OrderList.length <= 0">
          {{ $t('Global.Confirm') }}
        </CButton>
      </footer>
    </template>
  </CModal>
</template>

<script>
export default {
  name: "CreateLogisticsOrder",
  props: ['Toggle', 'OrderNum'],
  data() {
    return {
      Submit: false,
      Loading: true,
      LogisticsData: {
        ID: '',
        PrintFormat: 'Default'
      },
      OrderList: [],
      LogisticsOption: [],
      LogisticsListObject: {}
    }
  },
  computed: {
    CurrentChooseLogistics () {
      return this.LogisticsListObject[this.LogisticsData.ID] || {}
    },
    PrintFormatOptions() {
      return [
        {
          value: 'Default',
          label: this.$t('Logistics.PrintFormatOptions.Default')
        }
      ]
    },
  },
  mounted() {
   return Promise.all([
     this.GetLogistics()
   ]).then(() => {
     this.Loading = false
   }).catch((error) => {
     console.log(error)
     this.Loading = false
     this.$notify({
       group: 'notify',
       title: this.$t('Message.Error'),
       text: this.$t('Message.Global/ErrorMessage') + error,
       type: 'error'
     })
   })
  },
  watch: {
  },
  methods: {
    HandleHideModal() {
      this.$emit('update:Toggle', false)
      this.$emit('update:OrderNum', false)
    },
    GerOrderList() {
      const params = {
        Logistics: this.LogisticsListObject[this.LogisticsData.ID].Method,
        LogisticsID: this.LogisticsData.ID,
        LogisticsSystem: this.LogisticsListObject[this.LogisticsData.ID].System,
        LogisticsStatus: 'H',
        PaymentStatus: ['A', 'Y'].join(','),
        SearchAllTimeRange: true,
        OrderNum: this.OrderNum.join(',')
      }
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/order/systemList',
        method: 'get',
        params
      }).then(async ({data}) => {
        this.Loading = false
        this.OrderList = data.list
      }).catch((error) => {
        this.Loading = false
        throw error
      })
    },
    GetLogistics() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/system/logistics/list',
        method: 'get'
      }).then(({data}) => {
        this.LogisticsOption = Object.keys(data).filter(id => data[id].System === 'SF-LOGISTICS').map((id) => {
          return {
            value: id,
            label: `${data[id].Name} (${this.$t('Order.LogisticsType')}: ${this.$t('Logistics.LogisticsMethods.' + data[id].Method)}，${this.$t('Logistics.Fee')}: ${data[id].Fee}，${this.$t('Logistics.ServiceLocation')}: ${this.$t('Logistics.ServiceLocationOptions.' + data[id].ServiceLocation)})`
          }
        })
        this.LogisticsListObject = data
      }).catch((err) => {
        throw err
      })
    },
    async RedirectPrintShipmentLabel() {
      this.$Progress.start()
      const system = this.LogisticsListObject[this.LogisticsData.ID].System
      const method = this.LogisticsListObject[this.LogisticsData.ID].Method
      const PayCenterOrderNo = this.OrderList.filter(item => typeof item.LogisticsData?.OrderNo !== 'undefined').map(item => item.LogisticsData.OrderNo)
      this.$Progress.finish()
      if (PayCenterOrderNo.length > 0) {
        return window.open(`/import/order/redirectPrintShipmentLabel?Token=${localStorage.getItem('XX-CSRF-Token')}&System=${system.toUpperCase()}&Method=${method.toUpperCase()}&OrderData=${PayCenterOrderNo.join(',')}&PrintFormat=${this.LogisticsData.PrintFormat}`, '_blank')
      } else {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Order.NoOrderPrint'),
          type: 'warn'
        })
      }
    },
  }
}
</script>

<style lang="scss">
#PrintLabel {
  label {
    font-weight: bold;
  }
}
</style>
